import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Application from './views/application';
import './App.scss';
import './assets/scss/fonts.scss';
import AuthContext from './context/auth';
import SocketContext from './context/socket';
import { io } from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import News from "./views/news";
// import version from "./version.json"

const Styles = makeStyles((theme) => ({
    alphaNotice: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        color: '#fff',
    },
}));

function App() {
    const classes = Styles();

    const [auth, setAuth] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem('auth'));
        } catch (e) {
            localStorage.removeItem('auth');
            return null;
        }
    });
    const [socket, setSocket] = useState(null);

    const validateAuth = async () => {
        try {
            await axios.get('/api/auth', {headers: { 'x-auth-token': auth.token }});
        } catch(e) {
            localStorage.removeItem('auth');
            setAuth(null);
            window.location.href = '/login';
        }
        //const response = await axios.get('/api/auth', {headers: { 'x-auth-token': auth.token }});
        //
        // if(response.status === 401) {
        //     localStorage.removeItem('auth');
        //     setAuth(null);
        //     window.location.href = '/login';
        // }
    };

    useEffect(() => {
        if (!socket) {
            const socket = io();
            setSocket(socket);
        }

        if(auth) {
            validateAuth();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if(socket) {
    //         socket.on('io_res_has_room', (data) => {
    //             console.log(data, "res");
    //             if(!data) {
    //                 //logout and go to history if socket not existing
    //                 localStorage.removeItem('auth');
    //                 setAuth(null);
    //                 if (history) {
    //                     history.push('/login');
    //                 }
    //             }
    //         });
    //
    //         if(auth) {
    //             setTimeout((socket, id) => {
    //                 socket.emit('io_req_has_room', {id: id});
    //             }, 1000, socket, auth.id);
    //         }
    //     }
    // }, [socket, auth]); // eslint-disable-line react-hooks/exhaustive-deps

    const authProvider = useMemo(() => ({ auth, setAuth }), [auth]);
    const socketProvider = useMemo(() => ({ socket, setSocket }), [socket]);

    return (
        <AuthContext.Provider value={authProvider}>
            <ToastContainer position='top-center' />
            <SocketContext.Provider value={socketProvider}>
                    <Router>
                        <Application />
                        <Typography color='textSecondary' className={classes.alphaNotice}>
                            version 1.4.1
                            {/*version {version.label}*/}
                        </Typography>
                    {/*    <Routes>*/}
                    {/*        <Route path='/' element={<Application />} />*/}
                    {/*        <Route exact path='/news' element={<News />} />*/}
                    {/*    </Routes>*/}

                    {/*    <Typography color='textSecondary' className={classes.alphaNotice}>*/}
                    {/*        version 1.4.0*/}
                    {/*        /!*version {version.label}*!/*/}
                    {/*    </Typography>*/}
                    </Router>
            </SocketContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
