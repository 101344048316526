import React from 'react';
import './homelocationgrid.scss';
import {Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import locations2 from '../../assets/img/locations2.jpg';

import escort from "../../assets/img/escort.jpg";
import wellness from "../../assets/img/wellness.jpg";
import sm from "../../assets/img/bdsm_studio.jpg";
import rendRoom from "../../assets/img/zimmervermietung.jpg";
import nightClub from "../../assets/img/nightclub.jpg";

import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto'
    },
}));

const JezHomeLocationGrid = ({ children }) => {
    const classes = useStyles();

    return (
        <Container component='div' className={classes.maxWidth1170}>
            <div className='homelocationgrid-container'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={5}>
                        <Link to={'/locations/nightclub'}>
                            <div className='locationgridwrap firstimage'>
                                <div className={'locationgridimg'} style={{ backgroundImage: `url('${nightClub}')` }} />
                                <span className="tooltiptext">Jezzaber AMÜSIERCLUB Gentlemans Base Nightclub</span>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/tagesstudio'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${locations2}')` }} />
                                        <span className="tooltiptext">Jezzaber Gentlemans Base Tagesstudio mit Bar</span>
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/escort'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${escort}')` }} />
                                        <span className="tooltiptext">Escort</span>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/bdsm-studio'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${sm}')` }} />
                                        <span className="tooltiptext">BDSM Studio</span>
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/zimmervermietung'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${rendRoom}')` }} />
                                        <span className="tooltiptext">Zimmervermietung</span>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Link to={'/locations/wellness'}>
                            <div className='locationgridwrap firstimage'>
                                <div className={'locationgridimg'} style={{ backgroundImage: `url('${wellness}')` }} />
                                <span className="tooltiptext">Wellness</span>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default JezHomeLocationGrid;
